import React from "react";
import Link from "next/link";
import Head from "next/head";
import { useRouter } from "next/router";

const NotFoundPage = props => {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>Trang không tồn tại - Thị Trường Sỉ</title>
      </Head>
      <div className="flex flex-col items-center">
        <div>
          <img
            src="/static/images/404-page.jpg"
            width="300"
            height="330"
            className="user-select-none"
          />
        </div>
        <div className="text-center px-3">
          <h1 style={{ fontSize: "2.6rem" }}>Oops!</h1>
          <div>
            Trang bạn truy cập không tồn tại hoặc đã bị gỡ bỏ, hãy thử truy cập
            các trang khác trên Thị Trường Sỉ
          </div>
        </div>
        <div className="text-center">
          <Link href="/" className="btn btn-link">
            &laquo; Quay về trang chủ
          </Link>
        </div>

        <div className="text-gray-300 text-sm">{router.asPath}</div>
      </div>
    </>
  );
};

export default NotFoundPage;
